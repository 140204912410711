.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  color: #333333;
  padding: 6px 18px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.button:hover,
.button:focus {
  color: #3D9FD6;
  border-color: #3D9FD6;
}
@media (max-width: 1023px) {
  .button {
    padding: 8px 18px 12px;
  }
}
.section--slider .desk {
  max-width: calc(100% - 276px);
}
.section--three .area {
  margin-top: 67px;
  margin-bottom: 67px;
}
.moodcontent .area {
  margin-top: 10px;
}
.section--one .area .flat .pict {
  margin-top: 70px;
  margin-bottom: 70px;
}
.section--one .area .flat .pict + .pict {
  margin-top: -68px;
}
.section--three .area {
  width: 100%;
}
.section--three .area > .unit {
  margin-right: 2.76461295%;
  margin-left: 2.76461295%;
  width: 94.47077409%;
}
.section--three .area .cb-album .body,
.section--three .area .head,
.section--three .area .foot,
.section--three .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--three .area > .slim {
  width: 27.80410742%;
}
.section--three .area > .slim .head,
.section--three .area > .slim .foot,
.section--three .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--three .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--three .area > .slim .tiny {
  width: 100%;
}
.section--four .area {
  width: 100%;
}
.section--four .area > .unit {
  margin-right: 2.76461295%;
  margin-left: 2.76461295%;
  width: 94.47077409%;
}
.section--four .area .cb-album .body,
.section--four .area .head,
.section--four .area .foot,
.section--four .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim {
  width: 14.47077409%;
}
.section--four .area > .slim .head,
.section--four .area > .slim .foot,
.section--four .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim .tiny {
  width: 100%;
}
.section--three .area {
  width: 105.85284281%;
  margin-left: -2.9264214%;
}
.section--four .area {
  width: 105.85284281%;
  margin-left: -2.9264214%;
}
.section--two .area .pure,
.section--two .area .seam {
  margin-top: 45px;
  margin-bottom: 45px;
  min-height: 383px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media (max-width: 1023px) {
  .section--two .area .pure,
  .section--two .area .seam {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .section--two .area .pure,
  .section--two .area .seam {
    margin-top: 22.5px;
  }
}
@media (max-width: 1023px) {
  .section--two .area .pure,
  .section--two .area .seam {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .section--two .area .pure,
  .section--two .area .seam {
    margin-bottom: 22.5px;
  }
}
.section--two .area .pure .body,
.section--two .area .seam .body {
  margin-top: -20px;
  margin-bottom: -20px;
}
@media (max-width: 1023px) {
  .section--two .area .pure .body,
  .section--two .area .seam .body {
    margin-top: -15px;
  }
}
@media (max-width: 767px) {
  .section--two .area .pure .body,
  .section--two .area .seam .body {
    margin-top: -10px;
  }
}
@media (max-width: 1023px) {
  .section--two .area .pure .body,
  .section--two .area .seam .body {
    margin-bottom: -15px;
  }
}
@media (max-width: 767px) {
  .section--two .area .pure .body,
  .section--two .area .seam .body {
    margin-bottom: -10px;
  }
}
.section--two .area .pure .pict.tall:first-child,
.section--two .area .seam .pict.tall:first-child,
.section--two .area .pure .film.tall:first-child,
.section--two .area .seam .film.tall:first-child {
  width: 48.07692308%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  margin: 0;
}
.section--two .area .pure.wide,
.section--two .area .seam.wide {
  padding-left: 51.92307692%;
}
.section--two .area .pure.wide .pict.tall:first-child,
.section--two .area .seam.wide .pict.tall:first-child,
.section--two .area .pure.wide .film.tall:first-child,
.section--two .area .seam.wide .film.tall:first-child {
  left: 0;
}
.section--two .area .pure.slim,
.section--two .area .seam.slim {
  padding-right: 51.92307692%;
}
.section--two .area .pure.slim .pict.tall:first-child,
.section--two .area .seam.slim .pict.tall:first-child,
.section--two .area .pure.slim .film.tall:first-child,
.section--two .area .seam.slim .film.tall:first-child {
  right: 0;
}
/*# sourceMappingURL=./screen-large.css.map */